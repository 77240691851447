// Whenever possible, use this timeout function instead of setInterval or setTimeout to improve animatin preformance.
// Reference: https://blog.webdevsimplified.com/2021-12/request-animation-frame/
//  interval: Time interval in milliseconds
export const timeout = function(exec, interval, repeat=false) {
  let startTime = null
  // let lastTime = null

  function playFunction(time) {
    const loop = repeat || false
    const stop = false

    startTime = startTime || time

    // Note: The difference between the current time and the last time can be considered a 'frame rate'
    // const currentRate = time - lastTime

    const delta = time - startTime

    // Execute the provided function if we've exceeded the time interval
    if (delta >= interval) {
      exec()
      if (loop) {
        // If we keep looping, reset the starting time.
        startTime = time
      } else {
        // If we don't loop, flag that it's time to stop.
        stop = true
      }
    }

    if (!stop) {
      // lastTime = time
      window.requestAnimationFrame(playFunction)
    }
  }

  window.requestAnimationFrame(playFunction)
}