const DeveloperPlug = () => {
    return (

      <div className="looking-for-work-or-a-job" hidden>
        {`
        If you are a developer, designer, UX, UI, and/or QA, consider working with us here at Furnace Studios.
       
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#,*(@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*,,,**@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@%,,,(@(**@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@*,,,@@@@%,*#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#..,(@@@@@@@,*/@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@,..,@@@@@@@@@@***@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@#...(@@@@@@@@@@@@(**@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@,..,@@@@@@@@@@@@@@@%**%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@%..*@@@@@@@@@@@@@@@@@@**(@@@@%*(@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@(..(@@@@@@@@@@@@@@@@@@@***@@@%***@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@/..#@@@@@@@@@@@@@@@@@@@@(**@@%*****@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@*..%@@@@@@@@@@@@@@@@@@@@@%**%@**(@***@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@,..@@@@@@@@@@@@@@@@@@@@@@@@**/**/@@@*/(@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@...@@@@@@@@@@@@@@@@@@@@@@@@@****/@@@@%*/%@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@*..%@@@@@@@@@@@@@@@@@@@@@@@@@(**/@@@@@@(/*@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@../@@@@@@@@@@@@@@@@@@@@@@@@@%//@@@@@@@@///@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@*..@@@@@@@@@@@@@@@@@@@@@@@@@@/@@@@@@@@@@//(@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@../@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@(//#@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@*..@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#//(@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@.,/@@@@@@@@@@@@@@@@**@@@@@@@@@@@@@%///@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@*,,,%@@@@@@@@@@@@@/**/#@@@@@@@@@%///@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@*,*,#@@@@@@@@@(**@//(@@@@@@@///@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@(,*,/@@@@@(//@@@///@@@@*//%@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@%****%(*/@@@@@(//@///%@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/*/*%@@@@@@(///#@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#(@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
        
        `}
      </div>

    )
}

export default DeveloperPlug
