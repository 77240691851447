import React, { useState, useEffect, useRef } from 'react'
import Image from 'next/image'
import styles from '../styles/Home.module.scss'

import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'

import Modal from '../components/Modal';
import DeveloperPlug from '../components/sections/DeveloperPlug'

import { timeout } from '../components/utils/timeout.js'

export default function Home() {

  const [modal,toggleModal] = useState();

  useEffect(() => {
    toggleModal(modal);
    const overlay = document.querySelector('.contactModal');
    const content = overlay ? overlay.querySelector('.modalContent') : document.querySelector('.modalContent') ;
    if (overlay){
        content.style.opacity = '0';
        content.style.transform = 'translateY(-20px)';
        overlay.style.opacity = '1';
        overlay.style.display = 'flex';

        timeout(() => {
            content.style.display = 'flex'
        }, 10)

        timeout(() => {
            overlay.style.opacity = '1'
            content.style.transform = 'translateY(0px)'
            content.style.opacity = '1'
        }, 100)
    }
},[modal]);

const openModal = function(message = '') {
  const contentStyle = {};
  const modalMobileBackgroundStyle = {};
  const modalTextStyle = {
      position: `relative`,
      clipPath: `revert`,
      fontSize: '30px',
      fontWeight: '500'
  };

  const modalData = () => {
    return ( 
      <Modal name="contactModal" modalTheme="green" content={(
          <div className="d-flex w-11/12 items-center" style={contentStyle}>
            <div className="modalMobileBackground" style={modalMobileBackgroundStyle}></div>
            <div className="modalText relative px-0 md:px-8 md:py-10" style={modalTextStyle}>
              <div className="modalTextContent px-4 text-center">
                {message}
              </div>
            </div>
          </div>
      )}></Modal>
    )
  }

  toggleModal(modalData);
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validateForm = () => {
  const name = document.getElementById('name');
  const email = document.getElementById('email');
  const comments = document.getElementById('comments');

  const nameVal = name.value.trim() || ''
  const emailVal = email.value.trim() || ''
  const commentsVal = comments.value.trim() || ''

  if(nameVal.length <= 2) {
    name.parentNode.classList.add('error')
  } else {
    name.parentNode.classList.remove('error')
  }

  if(!validateEmail(emailVal)) {
    email.parentNode.classList.add('error')
  } else {
    email.parentNode.classList.remove('error')
  }

  if(commentsVal.length <= 2) {
    comments.parentNode.classList.add('error')
  } else {
    comments.parentNode.classList.remove('error')
  }

  return validateEmail(emailVal) && nameVal.length > 2 && commentsVal.length > 2
}

const fieldValidation = (e) => {

  const formField = document.getElementById(e.target.name);

  if(e.target.name === "name" || e.target.name === "comments") {
  
    if(e.target.value && e.target.value.trim().length > 2) {
      formField.parentNode.classList.remove('error')
    } else {
      console.log('ERROR')
      formField.parentNode.classList.add('error')
    }

  }

  if(e.target.name === "email") {

    if(validateEmail(e.target.value)) {

      formField.parentNode.classList.remove('error')

    } else {

      formField.parentNode.classList.add('error')
    }

  }
}

const clearForm = () => {
  document.getElementById('name').value = ''
  document.getElementById('email').value = ''
  document.getElementById('comments').value = ''
  document.getElementById('location').value = ''
}

const handleSubmit = (e) => {
  e.preventDefault();
  let myForm = document.getElementById("form")
  let formData = new FormData(myForm)

  // honeypot
  const location = document.getElementById('location');
  const locationVal = location.value.trim() || ''

  if(locationVal.length > 0) {
    let msg = 'Thank You!' // pretend to submit
    openModal(msg)
    clearForm()
  } else {

    if(validateForm()) {

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          let msg = 'Thank You!'
          openModal(msg)
          clearForm()
        })
        .catch((error) => {
          let msg = 'Error: Message not sent!'
          openModal(msg)
        })
    } else {
      return false
    }
  }
  
}


  return (
    <main className={`${styles.sitewrapper} main-wrapper relative h-screen`}>
      <Header canonical="https://furnacestudios.com" pageDescription="The Furnace is a collective of photographers, designers, videographers, editors, animators, illustrators, UX/UI specialists, developers,  QA analysts, retouchers and print production"></Header>

      <header id="nav" className="sticky w-full z-10 inline-flex justify-between align-items-center bg-black top-0 p-5">
        <a href="/" className="logo ml-1 lg:ml-5">
          <Image src="/images/logo.svg" loading="lazy" width={360} height={78} alt="Furnace Studios" />
        </a>
        <div className="social-icons align-item-center inline-flex">
          <a className="mx-2 align-item-center inline-flex" href="https://www.instagram.com/furnacestudios/" target="_blank" rel="nofollow">
            <Image src="/images/icon-instagram.svg" loading="lazy" width={40} height={40} alt="Instagram" />
          </a>
          <a className="mx-2 align-item-center inline-flex" href="https://www.linkedin.com/company/furnacestudios/" target="_blank"  rel="nofollow">
            <Image src="/images/icon-linkedin.svg" loading="lazy" width={40} height={40} alt="linkedin" />
          </a>

          <a className="mx-2 align-item-center inline-flex" href="https://www.tiktok.com/@furnacestudios" target="_blank"  rel="nofollow">
            <Image src="/images/icon-tiktok.svg" loading="lazy" width={40} height={40} alt="tiktok" />
          </a>
        </div>
      </header>

      <div className="container w-full px-5 inline-flex left-0 right-0 m-auto top-0 mb-5 pb-5 lg:mb-0 mt-0 lg:mt-0 pt-2 pb-5 lg:pt-0 lg:pb-0 lg:absolute relative lg:h-full justify-around items-center flex-col lg:flex-row">
        
        <div className="slideFade2 w-full relative lg:absolute right-2 pb-5 mb-5 lg:mt-5 lg:pt-5 top-0 lg:top-12 text-sm text-right text-blue">
            <br />
            furnace<br />
            est.2019<br />
            we're just down the cellar
        </div>
        
        <div className="slideFade w-5/5 px-5 lg:px-0 pb-5 lg:w-2/5">
          <p className="text-xl lg:text-2xl text-white mb-10 leading-8 lg:leading-9">
            <span className="text-light-blue font-semibold">The Furnace</span> is a collective of photographers, designers, videographers, editors, animators, illustrators, UX/UI specialists, developers,  QA analysts, retouchers and print production artists who execute a wide range of creative innovative projects. </p>
          <p className="text-xl lg:text-2xl text-white leading-8 lg:leading-9">Powered by ConcentricHX, The Furnace prioritizes creativity, efficency, operational excellence, constant learning, and timely delivery in its partnership with clients to promote their vision and exceed expectations. </p>
        </div>
  
        <div className="slideFade2 min-w-full lg:min-w-min w-5/5 mt-5 lg:mt-0 pt-5 lg:pt-0 px-5 lg:pb-0 pb-5 lg:px-5 lg:w-2/5 relative z-10">
            <h4 className="text-white mb-4 font-bold">GET IN TOUCH!</h4>
            <form id="form" onSubmit={handleSubmit} className="form" name="contact" method="POST" data-netlify="true">
            <input type="hidden" name="form-name" value="contact" />
              <label className="relative block mb-5">
                <input className="w-full p-4 rounded-md" type="text" id="name" name="name" onChange={fieldValidation} onBlur={fieldValidation} placeholder="Name" />
                <span className="error-message">
                  Please enter a valid name
                </span>
              </label>
              <label className="relative block mb-5">
                <input className="w-full p-4 rounded-md" type="email" id="email" name="email" onChange={fieldValidation} onBlur={fieldValidation} placeholder="Email" />
                <span className="error-message">
                  Please enter a valid email
                </span>
              </label>
              <label className="relative block mb-5">
                <textarea className="w-full p-4 rounded-md" id="comments" name="comments" onChange={fieldValidation} onBlur={fieldValidation} placeholder="Comments"></textarea>
                <span className="text-area error-message">
                  Please leave a message
                </span>
              </label>
              <label className="location" style={{ overflow: 'hidden', display: 'inline-block', width: '0px', height: '0px' }}>
                <input className="w-full p-4 rounded-md" type="text" id="location" name="location" onChange={fieldValidation} onBlur={fieldValidation} placeholder="Location" />
              </label>
              <button className="rounded-md font-semibold" type="submit">Send</button>
            </form>
           
        </div>

      </div>
      {modal}
      <div className="spinner -right-10 -bottom-20 absolute lg:bottom-20 px-4 py-4">
        <Image className="rotate" src="/images/img-circle-copy@2x.png" layout="raw" loading="lazy" width={335} height={334} alt="We're just down the cellar - est. 2019" />
      </div>
      
      <section className="relative lg:absolute bottom-0 mt-5 pt-5 lg:mt-0 w-full">
        {/* <img src="images/hero.jpg" className="object-cover w-full h-full" alt="Image alt text" /> */}
        <Footer></Footer>
      </section>

      {/* <Contact></Contact> */}

      <DeveloperPlug></DeveloperPlug>
    </main>
  )
}

export async function getStaticProps() {
  return { 
    props: { 
    },
    // NOTE: revalidate causes a 405 Method Not Allowed error on Netlify form submission.
    // revalidate: 10, // TODO: In seconds - will need to create hook
  }
}
