import Head from 'next/head'

const Header = ({title, canonical, pageDescription}) => {
  let pageTitle = `Furnace Studios`
  pageTitle = (title ? `${title} | ` : ``) + pageTitle

  // Define base URL. Note that since we build our site statically, the host name cannot be determined at runtime.
  const baseUrl = {
    development: "https://development--furnacestudios.netlify.app",
    release: "https://release--furnacestudios.netlify.app",
    production: "https://furnacestudios.com",
  }[process.env.NODE_ENV];

  // Generate schema.org ld+json.
  function addProductJsonLd() {
    const retVal = {
      __html: `{
                "@context": "https://schema.org/",
                "@type": "Corporation",
                "name": "Furnace Studios",
                "description": "${pageDescription}",
                "url": "https://furnacestudios.com/",
                "telephone": "+12126339700",
                "logo": "${baseUrl}/images/furnace-og-image.png",
                "image": "${baseUrl}/images/furnace-og-image.png",
                "sameAs": [
                  "https://www.instagram.com/furnacestudios",
                  "https://twitter.com/furnacestudios"
                ],
                "address": [
                  {
                    "@type": "PostalAddress",
                    "streetAddress": "285 Fulton Street, Floor 68",
                    "addressLocality": "New York",
                    "addressRegion": "NY",
                    "postalCode": "10007",
                    "addressCountry": "USA"
                  }
                ],
                "location": {
                  "@type": "Place",
                  "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": "40.71280308902151",
                    "longitude": "-74.01337540755834"
                  }
                }
              }`
      }
    // Minify ld+json
    retVal.__html = JSON.stringify(JSON.parse(retVal.__html))
    return retVal
  }

  return (
    <Head>
      <title>{pageTitle}</title>
  
      <meta name="description" content={pageDescription} />
      <meta name="author" content="Furnace Studios" />
      <meta name="viewport"content="width=device-width, initial-scale=1.0" />
      
      <meta property="og:url" content="https://furnacestudios.com" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={`${baseUrl}/images/furnace-og-image.png`} />
      <meta property="og:image" content={`${baseUrl}/images/furnace-og-image-square.png`} />
      
      <meta property="fb:app_id" content="1070696066279450" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="furnacestudios.com" />
      <meta property="twitter:url" content={baseUrl} />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={`${baseUrl}/images/furnace-og-image.png`} />
      <meta name="twitter:site" content="@furnacestudios" />
      <meta name="twitter:creator" content="@furnacestudios" />

      <link rel="icon" type="image/png" href="/favicon.ico" />
      <link rel="author" type="text/plain" href="/humans.txt" />
      <link rel="canonical" href={canonical || `https://furnacestudios.com`} />


      <link rel="preconnect" href="https://www.googletagmanager.com"></link>
      <link rel="preconnect" href="https://static.hotjar.com"></link>

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={addProductJsonLd()}
        key="product-jsonld"
      />
    </Head>
  )
}

export default Header;