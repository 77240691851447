import Image from 'next/image'
import React from 'react'

const Footer = () => {
  return (
    <div className="absolute flex bottom-0 items-center w-full justify-items-stretch px-5 text-blue text-md">
      <div>
      © {new Date().getFullYear()} Furnace Studios. All rights reserved
      </div>
      <div className="horizontal-blue-line align-itens-center px-5 inline-flex flex-auto"></div>
      <div className="furnace-icons inline-flex justify-self-center px-2">
        <Image src="/images/icon-furnace@2x.png" loading="lazy" width={62} height={90} alt="furnace icon" />
        <Image src="/images/icon-furnace@2x.png" loading="lazy" width={62} height={90} alt="furnace icon" />
        <Image src="/images/icon-furnace@2x.png" loading="lazy" width={62} height={90}  alt="furnace icon" />
      </div>
    </div>
  )
}

export default Footer
