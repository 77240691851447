import React, { useEffect } from 'react'

import { timeout } from './utils/timeout.js'

const Modal = ({ content, name, handleModal, index, data, modalContentClasses=``, modalTheme=`` }) => {
  const theme_red = `red`
  const theme_black = `black`
  const theme_green = `green`

  switch (modalTheme) {
    case theme_red:
    case theme_black:
    case theme_green:
      break
    default:
      modalTheme = theme_black
  }

  useEffect(() => {
    let modal = document.getElementsByClassName(name)[0]
    let prev = modal.getElementsByClassName('modal-prev')[0]
    let next = modal.getElementsByClassName('modal-next')[0]

    const KEYCODE_ESC = 27
    const KEYCODE_NEXT = 39
    const KEYCODE_PREV = 37

    const close = (e) => {
      // Close modal if the user uses the escape key.
      if (e.keyCode === KEYCODE_ESC) {
        closeModal()
      }
      // Go to the next modal if the user uses the right arrow key.
      if (next && modal.style.display === 'inline-flex' && e.keyCode === KEYCODE_NEXT) {
        next.click()
        next.focus()
      }
      // Go to the next modal if the user uses the left arrow key.
      if (prev && modal.style.display === 'inline-flex' && e.keyCode === KEYCODE_PREV) {
        prev.click()
        prev.focus()
      }
    }

    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[])

  const closeModal = function(e) {
    const overlay = document.querySelector("."+name);
    const content = overlay ? overlay.querySelector('.modalContent') : document.querySelector('.modalContent')

    if (overlay) {
      content.style.opacity = '0'
      content.style.transform = 'translateY(-20px)'
      
      timeout(() => {
        overlay.style.opacity = '0'
      }, 450)

      timeout(() => {
        overlay.style.display = 'none'
      }, 500)
    }
  }

  const nullClick = function(e) {
    e.stopPropagation()
  }
  
  const modalOverlay = {}
  const modalContent = {}
  const modalClose = {
    borderColor: '#313478'
  }

  return (
    <div onClick={closeModal} className={`${name} modalOverlay hidden fixed top-0 left-0 w-full h-full items-center justify-center bg-black bg-opacity-90 z-[999] transition ease-linear duration-500 transform-gpu`} style={modalOverlay}>
      <div onClick={nullClick} className={`${data ? 'has-arrows w-9/12 md:w-5/6' : ' w-11/12'} modalContent hidden flex-row max-w-[1500px] max-h-11/12 m-auto p-0 bg-cover rounded-md z-[100] transition ease-linear duration-500 transform-gpu ${modalContentClasses} ${modalTheme == theme_green ? `bg-green bg-opacity-90` : `bg-opacity-95`} ${modalTheme == theme_red ? `bg-rouge-red bg-opacity-90` : `bg-opacity-95`}`} style={modalContent}>
        <button aria-label={`Close ${name} modal`} className={`modalClose inline-flex justify-center align-center absolute top-4 right-4 w-6 h-6 hover:scale-90 before:-rotate-45 border border-solid z-[4] ${modalTheme == theme_red ? `border-white before:bg-white after:bg-white` : `border-rouge-red`}`} style={modalClose} onClick={closeModal}></button>
        {data ? <button aria-label={`Go to previous slide`} className="modal-prev before:right-auto before:active:right-auto before:left-[-3rem] md:before:left-[-4rem] before:active:left-[-4.1rem] before:rotate-180" onClick={handleModal} data-index={index-1 > 0 ? index-1 : data.length-1}></button> : <></>}
        {content}
        {data ? <button aria-label={`Go to next slide`} className="modal-next before:right-[-3.1rem] md:before:right-[-4rem]" onClick={handleModal} data-index={index++ < data.length-1 ? index++ : 0}></button> : <></>}
      </div>
    </div>
  )
}

export default Modal